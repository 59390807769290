<template>
  <b-modal :visible="visible" title="Informações do Cliente" @hide="onHide">
    <div>
      <p class="info-tip">
        Dica: Toque duas vezes em qualquer informação para copiá-la.
      </p>
      <p>
        <strong>Nome:</strong>
        <span
          @dblclick="copyToClipboard(customer.name)"
          @touchend="doubleTap('name', customer.name)"
        >
          {{ customer.name || 'Sem Informações' }}
        </span>
      </p>
      <p>
        <strong>Email:</strong>
        <span
          @dblclick="copyToClipboard(customer.email)"
          @touchend="doubleTap('email', customer.email)"
        >
          {{ customer.email || 'Sem Informações' }}
        </span>
      </p>
      <p>
        <strong>Telefone:</strong>
        <span
          @dblclick="copyToClipboard(customer.phone)"
          @touchend="doubleTap('phone', customer.phone)"
        >
          {{ customer.phone || 'Sem Informações' }}
        </span>
      </p>
      <p>
        <strong>Documento:</strong>
        <span
          @dblclick="copyToClipboard(customer.document)"
          @touchend="doubleTap('document', customer.document)"
        >
          {{ customer.document || 'Sem Informações' }}
        </span>
      </p>
      <p>
        <strong>Pontos Atuais:</strong>
        <span
          class="points-badge"
          @dblclick="copyToClipboard(totalPointsString)"
          @touchend="doubleTap('points', totalPointsString)"
        >
          {{ totalPoints }} / 10
        </span>
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CustomerInfoModal',
  props: {
    customer: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      lastTouch: {}
    }
  },
  computed: {
    totalPoints() {
      // Retorna o total de pontos ou 0 se não existir
      return (
        (this.customer.points && this.customer.points.total_rents_closed) || 0
      )
    },
    totalPointsString() {
      return this.totalPoints.toString()
    }
  },
  methods: {
    onHide() {
      this.$emit('close')
    },
    copyToClipboard(text) {
      if (!text) {
        this.$root.$toast.error('Sem informações para copiar.')
        return
      }
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$root.$toast.success('Informação copiada com sucesso!')
        })
        .catch((err) => {
          this.$root.$toast.error('Ocorreu um problema ao copiar.')
          console.log(err)
        })
    },
    doubleTap(key, text) {
      const now = Date.now()
      if (this.lastTouch[key] && now - this.lastTouch[key] < 400) {
        this.copyToClipboard(text)
        this.lastTouch[key] = 0
      } else {
        this.lastTouch[key] = now
      }
    }
  }
}
</script>

<style scoped>
.info-tip {
  font-size: 0.9em;
  color: #6c757d;
  margin-bottom: 16px;
}

span {
  cursor: pointer;
  transition: color 0.2s;
}

span:hover {
  color: #007bff;
  text-decoration: underline;
}

.points-badge {
  display: inline-block;
  border-radius: 4px;
  margin-left: 4px;
}
</style>
