<template>
  <PeriodsFetcher>
    <template #default="{ data }">
      <BaseSelect
        v-model="period"
        :options="parsePeriods(data)"
        :reduce="reduce"
        :validation="validation"
      />
    </template>
  </PeriodsFetcher>
</template>

<script>
import BaseSelect from '@/components/BaseSelect'
import { parsePeriodSelectOptions } from '@/helpers/periods'
import PeriodsFetcher from './PeriodsFetcher.vue'

export default {
  name: 'PeriodSelect',
  components: {
    BaseSelect,
    PeriodsFetcher
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    validation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    period: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    parsePeriods(periods) {
      return parsePeriodSelectOptions(periods)
    },
    reduce: ({ code }) => code
  }
}
</script>
