<template>
  <b-card
    no-body
    :img-src="toy.image"
    :img-alt="toy.name"
    img-top
    @click="handleCardClick"
  >
    <b-card-title
      class="flex-1 px-2 py-2 m-0 d-flex justify-content-between border-top"
      :class="statusClass"
    >
      <b-dropdown v-if="isActive" no-caret class="btn-cancel">
        <template #button-content>
          <span class="fas fa-ellipsis-v" />
        </template>
        <b-dropdown-item @click="onTriggerAction(RENT_ACTIONS.TRANSFER)">
          <span class="fas fa-exchange-alt mr-1" /> Transferir Aluguel
        </b-dropdown-item>
        <b-dropdown-item @click="onAddExtraTime()">
          <span class="fas fa-clock mr-1" /> Adicionar Tempo Extra
        </b-dropdown-item>
        <b-dropdown-item
          variant="danger"
          @click="onTriggerAction(RENT_ACTIONS.CANCEL)"
        >
          <span class="fas fa-ban mr-1" /> Cancelar
        </b-dropdown-item>
      </b-dropdown>
      <div class="w-100">
        <small class="d-flex gap-1">
          <span>{{ toy.name }}</span>
          <em v-if="isPaused"> (pausado) </em>
        </small>
        <span
          v-if="isActive || isPaused"
          class="d-flex flex-wrap align-items-center"
        >
          {{ toy.rent.customer.name }}
          <b-button variant="link" size="sm" @click="openCustomerModal">
            <span class="fas fa-info-circle"></span>
          </b-button>
        </span>
        <!-- Exibe o número do aluguel, mostra o botão de resgate se for 10 ou mais e adiciona checkbox "Não considerar" na direita -->
        <small v-if="isActive || isPaused" class="text-muted d-block">
          <div class="d-flex justify-content-between align-items-start">
            <!-- Coluna esquerda: número do aluguel e, opcionalmente, o botão de resgate em linha abaixo -->
            <div class="d-flex flex-column">
              <span>Aluguel N°: {{ getPoints + 1 }}</span>
              <b-button
                v-if="getPoints + 1 >= 10"
                size="sm"
                variant="outline-secondary"
                class="mt-1"
                @click="onRescueRent"
              >
                Resgatar
              </b-button>
            </div>
            <!-- Coluna direita: checkbox e ícone de interrogação com popover -->
            <!-- Coluna direita: checkbox e ícone de interrogação com popover -->
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="ignoreRentForPoint"
                name="ignoreRentForPoint"
                @click.native.stop
              >
                <span class="align-middle">Desconsiderar</span>
              </b-form-checkbox>
              <b-button
                v-b-popover.focus="
                  'Quando ativado, Não contabiliza esse aluguel para pontuação de fidelidade!'
                "
                variant="link"
                size="sm"
                class="p-0 ml-1"
              >
                <span class="fas fa-question-circle"></span>
              </b-button>
            </div>
          </div>
        </small>
      </div>
    </b-card-title>

    <b-card-text
      v-if="isActive || isPaused"
      class="p-2 m-0"
      :class="statusClass"
    >
      <div class="display-table">
        <div>
          <div class="text-right pr-2">
            <small><b>Período:</b></small>
          </div>
          <div>
            <small class="mr-2">{{ period }}</small>
            <b-button
              size="sm"
              variant="clear-primary"
              class="p-0"
              @click="onTriggerAction(RENT_ACTIONS.CHANGE_PERIOD)"
            >
              <span class="fas fa-redo" />
            </b-button>
          </div>
        </div>
        <div>
          <div class="text-right pr-2">
            <small><b>Tempo:</b></small>
          </div>
          <div>
            <small class="mr-2">{{ getElapsedTime }} min</small>
          </div>
        </div>

        <div v-if="extraTime">
          <div class="text-right pr-2">
            <small><b>Tempo Extra:</b></small>
          </div>
          <div>
            <small class="mr-2">{{ extraTime }} min</small>
          </div>
        </div>

        <div v-if="pausedTime">
          <div class="text-right pr-2">
            <small><b>Pausado:</b></small>
          </div>
          <div>
            <small class="mr-2">{{ pausedTime }} min</small>
          </div>
        </div>

        <div>
          <div class="text-right pr-2">
            <small><b>Início:</b></small>
          </div>
          <div>
            <small>{{ createdAt }}</small>
          </div>
        </div>

        <div>
          <div class="text-right pr-2">
            <small><b>Retorno:</b></small>
          </div>
          <div>
            <small>{{ endsAt }}</small>
          </div>
        </div>

        <div>
          <div class="text-right pr-2">
            <small><b>Valor:</b></small>
          </div>
          <div>
            <small>{{ amount | formatCurrency }}</small>
          </div>
        </div>
      </div>
      <div class="d-flex gap-1 flex-column mt-3">
        <div class="d-flex flex-wrap justify-content-stretch gap-1">
          <b-button
            variant="success"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.CASH)"
          >
            DI
          </b-button>
          <b-button
            variant="warning"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.CREDIT_CARD)"
          >
            CC
          </b-button>
          <b-button
            variant="info"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.DEBIT_CARD)"
          >
            CD
          </b-button>
          <b-button
            variant="secondary"
            class="flex-1"
            @click="onCloseRent(PAYMENT_METHODS_IDS.PIX)"
          >
            PIX
          </b-button>
          <b-button
            variant="outline-info"
            class="flex-1"
            @click="onCloseRent()"
          >
            <span class="fas fa-plus-circle" />
          </b-button>
        </div>
      </div>
    </b-card-text>

    <template v-if="!disabled" #footer>
      <div class="actions d-flex flex-column gap-0">
        <b-button
          v-if="isPaused"
          variant="outline"
          style="border-radius: 0 0 0.2rem 0.2rem !important"
          class="w-100 text-success"
          @click="onTriggerAction(RENT_ACTIONS.RESUME)"
        >
          <span class="fas fa-play mr-1" />
          Resumir
        </b-button>
        <template v-else-if="isActive">
          <b-button
            variant="outline"
            class="w-100 rounded-0 text-muted"
            @click="onTriggerAction(RENT_ACTIONS.PAUSE)"
          >
            <span class="fas fa-pause mr-1" />
            Pausar
          </b-button>
        </template>
        <div v-else class="w-100">
          <b-button
            variant="success"
            class="w-100 rounded-0"
            @click="onTriggerAction(RENT_ACTIONS.START)"
          >
            <span class="fas fa-flag-checkered mr-1" />
            Iniciar
          </b-button>
          <b-button
            variant="outline-success"
            style="border-radius: 0 0 0.2rem 0.2rem !important"
            class="w-100 rounded-0"
            :class="{ 'btn-darken': !isReopenButtonEnabled }"
            :disabled="!isReopenButtonEnabled"
            @click="onTriggerAction(RENT_ACTIONS.REOPEN_LAST_RENT)"
          >
            <i v-if="!isReopenButtonEnabled" class="fas fa-lock"></i>
            <span v-if="isReopenButtonEnabled" class="fas fa-redo mr-1" />
            Voltar último aluguel
          </b-button>
        </div>
      </div>
    </template>

    <customer-info-modal
      v-if="isActive || isPaused"
      :customer="toy.rent.customer"
      :visible="showCustomerModal"
      @close="showCustomerModal = false"
    />
  </b-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PAYMENT_METHODS, PAYMENT_METHODS_IDS, RENT_ACTIONS } from '@/constants'
import { format } from 'date-fns'
import { formatCurrency } from '@/helpers/numbers'
import CustomerInfoModal from './partials/CustomerInfoModal.vue'
import {
  calculateEndDate,
  calculatePeriod,
  isActive,
  isDelayed,
  isPaused
} from '@/helpers/rents'

export default {
  name: 'ToyRentListItem',
  components: {
    CustomerInfoModal
  },
  filters: {
    formatCurrency
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    },
    selectionModeActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCustomerModal: false,
      lastTap: 0,
      tapTimeout: null,
      elapsedTime: 0,
      rentAmount: 0,
      productsAmount: 0,
      currentPausedTime: 0,
      ignoreRentForPoint: false
    }
  },
  computed: {
    ...mapState('checkout', ['currentCheckout']),
    ...mapState('store', ['currentStore']),
    ...mapGetters('periods', ['periodsSortedByPrice']),
    isActive() {
      const rent = this.toy.rent
      return isActive(rent)
    },
    isPaused() {
      return isPaused(this.toy.rent)
    },
    isDelayed() {
      return isDelayed(this.endDate)
    },
    period() {
      return calculatePeriod(this.toy.rent)
    },
    createdAt() {
      if (this.toy.rent) {
        const createdAt = new Date(this.toy.rent.createdAt)
        return format(createdAt, "HH'h'mm")
      }
      return ''
    },
    endsAt() {
      return this.toy.rent ? format(this.endDate, "HH'h'mm") : ''
    },
    endDate() {
      return calculateEndDate(this.toy.rent)
    },
    extraTime() {
      return this.toy.rent.extraTime
        ?.filter(({ type }) => type !== 'pause')
        .reduce((acc, { minutes }) => acc + minutes, 0)
    },
    pausedTime() {
      return this.toy.rent.pausedTime
    },
    statusClass() {
      if (this.isPaused) {
        return 'alert-warning'
      }
      if (this.isDelayed) {
        return 'alert-danger'
      }
      return ''
    },
    amount() {
      return this.toy.rent.rentAmount + this.productsAmount
    },
    getElapsedTime() {
      return this.toy.rent.elapsedTime
    },
    getPoints() {
      return this.toy.rent.customer.points?.total_rents_closed
        ? this.toy.rent.customer.points?.total_rents_closed
        : 0
    },
    isReopenButtonEnabled() {
      // Se não existir checkoutId no último aluguel, retorna true (habilitado)
      if (
        !this.toy.rents ||
        !this.toy.rents[0] ||
        !this.toy.rents[0].checkoutId
      ) {
        return false
      }
      // Caso exista, habilita se checkoutId for igual ao id do checkout atual
      return this.toy.rents[0].checkoutId === this.currentCheckout?.id
    }
  },
  created() {
    console.log(
      `${this.toy.name} `,
      this.toy.rents[0].checkoutId + ' X ' + this.currentCheckout.id
    )
    this.RENT_ACTIONS = RENT_ACTIONS
    this.PAYMENT_METHODS_IDS = PAYMENT_METHODS_IDS
    this.PAYMENT_METHODS = PAYMENT_METHODS
  },
  methods: {
    openCustomerModal() {
      this.showCustomerModal = true
    },
    handleCardClick(event) {
      const currentTime = new Date().getTime()
      const tapLength = currentTime - this.lastTap
      clearTimeout(this.tapTimeout)
      if (tapLength < 300 && tapLength > 0) {
        this.handleDoubleClick(event)
      } else {
        this.tapTimeout = setTimeout(() => {
          this.handleSingleClick(event)
          clearTimeout(this.tapTimeout)
        }, 300)
      }
      this.lastTap = currentTime
    },
    handleDoubleClick() {
      if (this.toy.rent && (this.isActive || this.isPaused)) {
        this.$emit('activate-selection-mode', this.toy)
      }
    },
    handleSingleClick() {
      if (
        this.selectionModeActive &&
        (!this.toy.rent || (!this.isActive && !this.isPaused))
      ) {
        this.$emit('new-rental', this.toy)
      }
    },
    onAddExtraTime() {
      const { elapsedTime, rentAmount } = this
      this.onTriggerAction(RENT_ACTIONS.ADD_EXTRA_TIME, {
        elapsedTime,
        rentAmount
      })
    },
    onCloseRent(paymentMethod) {
      const hasProducts =
        this.toy.rent &&
        this.toy.rent.product &&
        this.toy.rent.product.length > 0
      const elapsedTime = this.getElapsedTime
      this.onTriggerAction(RENT_ACTIONS.CLOSE, {
        elapsedTime,
        hasProducts,
        paymentMethod,
        ignoreRentForPoint: this.ignoreRentForPoint
      })
    },
    async onRescueRent() {
      // Utilizando quebras de linha para separar a mensagem principal do aviso.
      const message = `Deseja realmente resgatar o aluguel grátis para ${this.toy.rent.customer.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message, {
        title: 'Confirmação',
        size: 'sm',
        okVariant: 'success',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        centered: true
      })
      if (result) {
        this.onTriggerAction(RENT_ACTIONS.RESCUE)
      }
    },
    onTriggerAction(action, data) {
      this.$emit('trigger-action', { action, toy: this.toy, data })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-darken {
  filter: brightness(0.1);
}

.card-footer {
  padding: 0px;
}

.actions {
  column-count: 4;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.display-table {
  display: table;
}
.display-table > div {
  display: table-row;
}
.display-table > div > div {
  display: table-cell;
}

.card {
  max-width: calc((100% / 3) - 0.5em);
  flex: 1 0 calc((100% / 3) - 0.5em);

  @media (max-width: 768px) {
    max-width: calc(100% / 2);
    flex: 1 0 calc((100% / 2) - 0.5em);
  }

  @media (min-width: 1200px) {
    max-width: calc((100% / 4) - 0.5em);
    flex: 1 0 calc((100% / 4) - 0.5em);
  }
}

.toy-name {
  font-size: calc(1.7vh);
}

.btn-cancel {
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn {
  font-size: 13px;
}
</style>
