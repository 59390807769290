<template>
  <b-form novalidate>
    <!-- Campo Tempo em Minutos -->
    <b-form-group
      label="Tempo em Minutos"
      label-for="time"
      :class="validationClass"
    >
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-secondary" @click="decrementTime"
            >-</b-button
          >
        </b-input-group-prepend>
        <b-form-input
          id="time"
          v-model.number="form.time"
          :state="validation.time"
          type="number"
          min="0"
          placeholder="Informe o tempo"
        />
        <b-input-group-append>
          <b-button variant="outline-secondary" @click="incrementTime"
            >+</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback v-if="$v.form.time.$invalid">
        Informe o tempo em minutos
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- Campo Motivo -->
    <b-form-group
      label="Motivo"
      label-for="reason"
      :class="reasonValidationClass"
    >
      <!-- Se não selecionou "Outro", mostra o select -->
      <template v-if="!otherSelected">
        <b-form-select
          id="reason"
          v-model="form.reason"
          :state="validation.reason"
          @change="handleReasonChange"
        >
          <b-form-select-option :value="null" disabled>
            Selecione um motivo
          </b-form-select-option>
          <b-form-select-option
            v-for="(item, index) in reasons"
            :key="index"
            :value="item"
          >
            {{ item }}
          </b-form-select-option>
          <!-- Opção "Outro" -->
          <b-form-select-option value="Outro"> Outro </b-form-select-option>
        </b-form-select>
      </template>

      <!-- Se selecionou "Outro", mostra o campo de texto com botão "X" -->
      <template v-else>
        <b-input-group>
          <b-form-input
            id="reason"
            v-model="form.reason"
            :state="validation.reason"
            type="text"
            placeholder="Informe o motivo"
          />
          <b-input-group-append>
            <b-button variant="outline-secondary" @click="resetOther">
              X
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
      <b-form-invalid-feedback v-if="$v.form.reason.$invalid">
        Selecione ou informe um motivo
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'

export default {
  name: 'ToyRentAddExtraTimeModalForm',
  mixins: [withFormValidation({ fields: ['time', 'reason'] })],
  validations: {
    form: {
      time: { required },
      reason: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Inicializa os valores padrão do formulário
      form: {
        time: 0,
        reason: ''
      },
      // Lista de motivos pré-definidos
      reasons: [
        'Autista',
        'Aniversario',
        'Troca de bateria',
        'Esquecido de Finalizar',
        'Erro do Sistema'
      ],
      // Flag para controle se a opção "Outro" foi selecionada
      otherSelected: false
    }
  },
  computed: {
    validationClass() {
      return {
        'is-invalid': this.$v.form.time.$dirty && this.$v.form.time.$invalid
      }
    },
    reasonValidationClass() {
      return {
        'is-invalid': this.$v.form.reason.$dirty && this.$v.form.reason.$invalid
      }
    }
  },
  destroyed() {
    this.form.time = 0
    this.form.reason = ''
  },
  methods: {
    incrementTime() {
      // Garante que o valor seja numérico
      this.form.time = Number(this.form.time) || 0
      this.form.time++
    },
    decrementTime() {
      this.form.time = Number(this.form.time) || 0
      if (this.form.time > 0) {
        this.form.time--
      }
    },
    handleReasonChange(value) {
      if (value === 'Outro') {
        this.otherSelected = true
        // Limpa o valor para que o usuário possa digitar o motivo
        this.form.reason = ''
      } else {
        this.otherSelected = false
      }
    },
    resetOther() {
      // Reseta o campo de texto e volta para o modo select
      this.otherSelected = false
      this.form.reason = null
    }
  }
}
</script>
